import tutorialConstants from '~/store/tutorial/-constants'

export default async function ({ app, store }) {
  if (!store.state.tutorial.cookieLoaded && app.$cookiz.get('tutorial')) {
    await store.dispatch(
      tutorialConstants.namespaceAction.LOAD_FROM_COOKIES,
      app.$cookiz.get('tutorial')
    )
  }
}
