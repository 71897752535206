import { ActionContext } from 'vuex'
import constants from './-constants'
import { TutorialState } from '~/store/tutorial/-types'

const { TRIGGER_PANEL, LOAD_FROM_COOKIES } = constants.action
const { SET_PANEL, SET_STATE } = constants.mutation

export default {
  [TRIGGER_PANEL]({ commit, state }: ActionContext<any, any>, panel: string) {
    commit(SET_PANEL, panel)
  },
  [LOAD_FROM_COOKIES]({ commit, state }: ActionContext<any, any>, newState: TutorialState) {
    commit(SET_STATE, newState)
  }
}
