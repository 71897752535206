import constants from './-constants'
import { GuidedTourListItem, GuidedTourLocation, GuidedTourState } from './-types'

const {
  MUTATE_AVAILABLE_TOURS,
  MUTATE_GUIDED_TOUR_IDENTIFIER,
  MUTATE_GUIDED_TOUR_DETAILS,
  MUTATE_IS_PLAYING,
  MUTATE_ON_CLEAR_PRESENTATION,
  MUTATE_ON_SHOW_PRESENTATION,
  MUTATE_SKIP_WALKTHROUGH,
  MUTATE_SKIP_ANIMATION,
  MUTATE_LOCATIONS,
  MUTATE_CURRENT_LOCATION,
  MUTATE_AUTO_CONTINUE_DELAY,
  MUTATE_AUTO_CONTINUE_TIMEOUT,
  MUTATE_TIME_TO_STAY,
  MUTATE_TIME_TO_STAY_TIMEOUT,
  MUTATE_SHOULD_BYPASS_DELAY,
  MUTATE_GUIDED_TOUR_MODE_ENABLED
} = constants.mutation

export default {
  [MUTATE_AVAILABLE_TOURS](state: GuidedTourState, payload: GuidedTourListItem[]) {
    state.availableTours = payload
  },
  [MUTATE_GUIDED_TOUR_IDENTIFIER](state: GuidedTourState, payload: string) {
    state.uuid = payload
  },
  [MUTATE_GUIDED_TOUR_DETAILS](state: GuidedTourState, payload: any) {
    state.data = payload
  },
  [MUTATE_IS_PLAYING](state: GuidedTourState, payload: boolean) {
    state.isPlaying = payload
  },
  [MUTATE_ON_CLEAR_PRESENTATION](state: GuidedTourState, payload: () => void) {},
  [MUTATE_ON_SHOW_PRESENTATION](
    state: GuidedTourState,
    payload: (location: GuidedTourLocation) => void
  ) {},
  [MUTATE_SKIP_WALKTHROUGH](state: GuidedTourState, payload: boolean) {
    state.skipWalkthrough = payload
  },
  [MUTATE_SKIP_ANIMATION](state: GuidedTourState, payload: boolean) {
    state.skipAnimation = payload
  },
  [MUTATE_LOCATIONS](state: GuidedTourState, payload: GuidedTourLocation[]) {
    const locations = [...payload]
    locations.sort((a, b) => {
      if (a.sequence_order < b.sequence_order) {
        return -1
      }
      if (a.sequence_order > b.sequence_order) {
        return 1
      }
      return 0
    })
    state.locations = locations
  },
  [MUTATE_CURRENT_LOCATION](state: GuidedTourState, payload: string) {
    state.currentLocation = payload
  },
  [MUTATE_AUTO_CONTINUE_DELAY](state: GuidedTourState, payload: number) {
    state.autoContinueDelay = payload
  },
  [MUTATE_AUTO_CONTINUE_TIMEOUT](state: GuidedTourState, payload: any) {
    state.autoContinueTimeout = payload
  },
  [MUTATE_TIME_TO_STAY](state: GuidedTourState, payload: number) {
    state.timeToStay = payload
  },
  [MUTATE_TIME_TO_STAY_TIMEOUT](state: GuidedTourState, payload: any) {
    state.timeToStayTimeout = payload
  },
  [MUTATE_SHOULD_BYPASS_DELAY](state: GuidedTourState, payload: boolean) {
    state.shouldBypassDelay = payload
  },
  [MUTATE_GUIDED_TOUR_MODE_ENABLED](state: GuidedTourState, payload: boolean) {
    state.guidedTourModeEnabled = payload
  }
}
