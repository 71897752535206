import { Client3DManager } from '@bright-spaces/engine-3d'

export type Project = {
  id: number
  landlord_id: number
  slug: string
  name: string
}

export type Building = {
  id: number
  code: string
  name: string
  project: Project
}

export type Floor = {
  id: number
  order_no: number
  no: number
  code: string
  name: string
  building: Building
}

export enum LocationType {
  PRESENTATION = 'presentation',
  GATEWAY = 'gateway'
}

export enum AnimationType {
  FAST_TRAVEL = 'fast-travel',
  FADE = 'fade'
}

export enum ContentAttachmentType {
  TEXT = 'text',
  VIDEO = 'video',
  IMAGE = 'image',
  IFRAME = 'iframe',
  EMBED = 'embed'
}

export type CameraCoordinate = {
  level: string
  order: number
  camera_position_x: number
  camera_position_y: number
  camera_position_z: number
  focus_target_x: number
  focus_target_y: number
  focus_target_z: number
}

export type Space = {
  id: number
  code: string
  space_code: string
  alias: string
  number_of_rooms: number
  number_of_bathrooms: number
  number_of_balconies: number
  util_sqm: number
  constructed_sqm: number
  has_balcony: boolean
  sqm_range: string
  is_public: boolean
  type: boolean
  is_dual_level: boolean
  available: boolean
  lease_start?: any
  lease_end?: any
  available_from?: any
  camera_position_x: number
  camera_position_y: number
  camera_position_z: number
  focus_target_x: number
  focus_target_y: number
  focus_target_z: number
  image: string
  orientation: string
  has_lake_view: boolean
  floor_id: number
  building_id: number
  project_id: number
  can_combine_with_id?: any
  description?: any
  matterport_url?: any
  price: number
  createdAt: string
  updatedAt: string
  deletedAt?: any
  customer_id?: any
  floor: Floor
  camera_coordinates: CameraCoordinate[]
}

export type GuidedTourLocation = {
  id: number
  uuid: string
  virtual_tour_id: number
  sequence_order: number
  show_in_timeline: boolean
  autocontinue_enabled: boolean
  autocontinue_delay_ms: number
  travel_time_ms: number
  time_to_stay_ms: number
  animation_type: AnimationType
  point_name: string
  point_note: string
  name: string
  description: string
  type: LocationType
  content_title?: any
  content_description?: any
  content_attachment_type?: ContentAttachmentType
  content_attachment_source?: any
  camera_position_x: number
  camera_position_y: number
  camera_position_z: number
  focus_target_x: number
  focus_target_y: number
  focus_target_z: number
  createdAt: string
  updatedAt: string
  deletedAt?: any
}

export type GuidedTourData = {
  id: number
  uuid: string
  name: string
  description: string
  space_id: number
  fitout_identifier: string
  createdAt: string
  updatedAt: string
  deletedAt?: any
  locations: GuidedTourLocation[]
  space: Space
}

export type GuidedTourListItem = {
  id: number
  uuid: string
  name: string
  description: string
  fitout_identifier: string
}

export type GuidedTourState = {
  availableTours: GuidedTourListItem[]
  uuid: string
  data: GuidedTourData | undefined
  locations: any[]
  autoContinueTimeout: any
  autoContinueDelay: number
  timeToStayTimeout: any
  timeToStay: number
  currentLocation: string
  shouldBypassDelay: boolean
  guidedTourModeEnabled: boolean
  skipWalkthrough: boolean
  skipAnimation: boolean
  isPlaying: boolean
}
