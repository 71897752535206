
import baseConstants from '~/store/base/-constants'
import universeConstants from '~/store/universe/-constants'

export default {
  name: 'UniverseLayout',
  data() {
    return {
      loadingScreenActive: true
    }
  },
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    sections() {
      return this.$store.state.base.meta.landingConfig.sections.filter(
        (section) => section.isEnabled
      )
    },
    loaderMetadata() {
      return this.$store.state.base?.meta?.landingConfig?.meta?.loaderMetadata || null
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    instagramUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.facebookUrl
    },
    staticPages() {
      return this.generalConfig.staticPages || []
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false
    }
  },

  mounted() {
    const self = this
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', self.smoothScroll)
    })
    window.addEventListener('keydown', this.closeInfoModal)

    if (this.$route.hash && this.$route.hash !== '') {
      document.querySelector(this.$route.hash)?.scrollIntoView({
        behavior: 'smooth'
      })
    }

    this.deactivateLoading()
  },

  updated() {},
  beforeDestroy() {
    const self = this
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach((anchor) => {
      anchor.removeEventListener('click', self.smoothScroll)
    })
    window.removeEventListener('keydown', this.closeInfoModal)
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  },
  methods: {
    closeInfoModal() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: false
        }
      )
    },
    smoothScroll(e) {
      e.preventDefault()
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_SIDEBAR))
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_FILTER))
      document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      })
    },
    deactivateLoading() {
      setTimeout(() => {
        this.loadingScreenActive = false
      }, 1000)
    }
  }
}
