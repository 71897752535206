import constants from './-constants'
import { UniverseState } from './-types'

const {
  SET_UNIVERSE_HELPER_METHODS,
  SET_PROJECTS_CARDS_STATE,
  SET_CARD_UPDATED_STATE,
  SET_INFO_BOX_STATE,
  SET_LOADER_STATE,
  SET_SHORTLIST_INFO_STATE,
  SET_APARTMENT_VIEW_MODE_STATE
} = constants.mutation

export default {
  [SET_UNIVERSE_HELPER_METHODS](state: UniverseState, { methods }: any) {
    state.universe.methods = methods
  },
  [SET_PROJECTS_CARDS_STATE](state: UniverseState, payload: any) {
    state.cards = payload.cards
  },
  [SET_CARD_UPDATED_STATE](
    state: UniverseState,
    // eslint-disable-next-line camelcase
    { mesh_id, open }: { mesh_id: string; open: boolean }
  ) {
    // eslint-disable-next-line camelcase
    const card = state.cards.find((c: any) => c.mesh_id === mesh_id)
    if (card) {
      card.open = open
    }
  },
  [SET_INFO_BOX_STATE](state: UniverseState, { open }: { open: boolean }) {
    state.infoBox.open = open
  },
  [SET_SHORTLIST_INFO_STATE](state: UniverseState, { open }: { open: boolean }) {
    state.shortlistInfo.open = open
  },
  [SET_APARTMENT_VIEW_MODE_STATE](state: UniverseState, open: boolean) {
    state.apartmentView = open
  },
  [SET_LOADER_STATE](state: UniverseState, { isLoading }: { isLoading: boolean }) {
    state.universe.loader.isLoaderActive = isLoading
  }
}
