// Actions
const LOAD_PROJECT_AVAILABILITY_DATA = 'loadProjectAvailabilityData'
const LOAD_BUILDING_AVAILABILITY_DATA = 'loadBuildingAvailabilityData'
const LOAD_FLOOR_AVAILABILITY_DATA = 'loadFloorAvailabilityData'
const LOAD_SPACE_AVAILABILITY_DATA = 'loadSpaceAvailabilityData'
const UPDATE_FILTERS = 'updateFilters'
const UPDATE_LEASED_SPACES_VISIBILITY = 'updateLeasedSpacesVisibility'
const CLEAR_FILTERS = 'clearFilters'
const LOAD_SPACE_STATISTICS_DATA = 'loadSpaceStatisticsData'

// mutations
const SET_PROJECT_AVAILABILITY_DATA = 'setProjectAvailabilityData'
const SET_BUILDING_AVAILABILITY_DATA = 'setBuildingAvailabilityData'
const SET_FLOOR_AVAILABILITY_DATA = 'setFloorAvailabilityData'
const SET_SPACE_AVAILABILITY_DATA = 'setSpaceAvailabilityData'
const SET_FILTERS = 'setFilters'
const RESET_PROJECT_AVAILABILITY_DATA = 'resetProjectAvailabilityData'
const RESET_BUILDING_AVAILABILITY_DATA = 'resetBuildingAvailabilityData'
const RESET_FLOOR_AVAILABILITY_DATA = 'resetFloorAvailabilityData'
const RESET_SPACE_AVAILABILITY_DATA = 'resetSpaceAvailabilityData'
const RESET_AVAILABILITY_DATA = 'resetAvailabilityData'
const RESET_FILTERS = 'resetFilters'
const SET_IS_LOADING_DATA = 'setIsLoadingData'
const SET_IS_LOADING_STATISTICS_DATA = 'setIsLoadingStatisticsData'
const SET_LEASED_SPACES_VISIBILITY = 'setLeasedSpacesVisibility'
const SET_SPACE_STATISTICS_DATA = 'setSpaceStatisticsData'

// Getters
const GET_PROJECT_BUILDINGS = 'getProjectBuildings'
const GET_BUILDING_FLOORS = 'getBuildingFloorsSpace'
const GET_FLOOR_SPACES = 'getFloorSpaces'
const GET_BUILDING_SPACES = 'getBuildingSpaces'
const GET_PROJECT_SPACES = 'getProjectSpaces'
const GET_FILTERS = 'getFilters'

// Namespace
const NAMESPACE = 'availability'

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    LOAD_PROJECT_AVAILABILITY_DATA,
    LOAD_BUILDING_AVAILABILITY_DATA,
    LOAD_FLOOR_AVAILABILITY_DATA,
    LOAD_SPACE_AVAILABILITY_DATA,
    UPDATE_FILTERS,
    CLEAR_FILTERS,
    UPDATE_LEASED_SPACES_VISIBILITY,
    LOAD_SPACE_STATISTICS_DATA
  },
  mutation: {
    SET_PROJECT_AVAILABILITY_DATA,
    SET_BUILDING_AVAILABILITY_DATA,
    SET_FLOOR_AVAILABILITY_DATA,
    SET_SPACE_AVAILABILITY_DATA,
    SET_FILTERS,
    RESET_PROJECT_AVAILABILITY_DATA,
    RESET_BUILDING_AVAILABILITY_DATA,
    RESET_FLOOR_AVAILABILITY_DATA,
    RESET_SPACE_AVAILABILITY_DATA,
    RESET_AVAILABILITY_DATA,
    RESET_FILTERS,
    SET_IS_LOADING_DATA,
    SET_LEASED_SPACES_VISIBILITY,
    SET_SPACE_STATISTICS_DATA,
    SET_IS_LOADING_STATISTICS_DATA
  },
  getter: {
    GET_PROJECT_BUILDINGS,
    GET_BUILDING_FLOORS,
    GET_FLOOR_SPACES,
    GET_BUILDING_SPACES,
    GET_PROJECT_SPACES,
    GET_FILTERS
  },
  namespace: NAMESPACE,
  withNamespace
}
