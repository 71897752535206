export default function ({ app: { i18n, $cookiz }, route, store }) {
  if (i18n.locales.includes(route.query.langCode)) {
    i18n.setLocale(store.state.base.language)
    return
  }
  if (!i18n.locales.includes(route.query.langCode)) {
    const lang = $cookiz.get('language-cookie')
    i18n.setLocale(lang)
  }
}
