
import AtomLogo from '~/components/atoms/common/AtomLogo.vue'
import AtomLocaleSwitch from '~/components/atoms/common/AtomLocaleSwitch.vue'

export default {
  name: 'UnderConstructionLayout',
  components: { AtomLocaleSwitch, AtomLogo },
  head() {
    const { generalConfig, projectConfig } = this.$store.state.base.meta
    return {
      title: generalConfig.baseTitle + ' - ' + projectConfig.meta.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: projectConfig.meta.description
        }
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: generalConfig.favicon
        }
      ]
    }
  }
}
