// Actions
const ADD_SPACE = 'addSpace'
const REMOVE_SPACE = 'removeSpace'
const CHANGE_CART_SPACES_STATE = 'changeCartSpacesState'
const CLEAR_ALL = 'clearAll'
const CLEAR_SPACES_FROM_BUILDING = 'clearSpacesFromBuilding'
// Mutations
const SET_SPACES = 'setSpaces'
const SET_CART_SPACES_STATE = 'setCartSpacesState'
const SET_SPACES_BY_BUILDING = 'setSpacesByBuilding'

// Actions & Mutations
const CLEAR_STATE = 'clearState'

// Namespace
const NAMESPACE = 'requestOffer'

// Cache
const CACHE_NAME = 'requestOfferCache'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    ADD_SPACE,
    REMOVE_SPACE,
    CLEAR_STATE,
    CHANGE_CART_SPACES_STATE,
    CLEAR_ALL,
    CLEAR_SPACES_FROM_BUILDING
  },
  mutation: {
    SET_SPACES,
    CLEAR_STATE,
    SET_CART_SPACES_STATE,
    SET_SPACES_BY_BUILDING
  },
  namespace: NAMESPACE,
  cacheName: CACHE_NAME,
  withNamespace
}
