import { ActionContext } from 'vuex'
import constants from './-constants'
import { WebRequest } from '~/helpers/api'

const { LOAD_SPACE_DETAILS } = constants.action
const { SET_SPACE_DETAILS } = constants.mutation

export default {
  [LOAD_SPACE_DETAILS]({ commit, rootGetters }: ActionContext<any, any>, payload: any) {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      WebRequest.GET(this.$webApi.landlord.space(payload))
        .then((res: any) => {
          commit(SET_SPACE_DETAILS, res.data.result)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(SET_SPACE_DETAILS, false)
          reject(new Error(e.message))
        })
    })
  }
}
