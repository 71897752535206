export default ({ app, store }) => {
  if (process.server) {
    return
  }
  const isProd = app.$config.RUNTIME_ENV === 'production'
  const existingScripTag = document.querySelector('script#bright-analytics-script-tag')
  if (existingScripTag) {
    return
  }
  const { generalConfig } = store.state.base.meta
  if (!generalConfig.brightAnalyticsEnabled) {
    return
  }
  const script = document.createElement('script')
  script.id = 'bright-analytics-script-tag'
  script.type = 'text/javascript'
  // script.async = true
  script.src = `//analytics.api${!isProd ? '.dev' : ''}.brightspaces.tech/static/analytics${
    !isProd ? '-dev' : ''
  }.js`

  script.onload = async () => {
    await window.analyticsLayerInitialized
    window.analyticsLayer.identifier = generalConfig.brightTrackingId
    window.analyticsLayer.send({ event: 'page-view', payload: {} })
  }

  document.head.appendChild(script)
}
