import baseConstants from '~/store/base/-constants'
import projectConstants from '~/store/project/-constants'

export default async function ({ store, route }) {
  if (Object.keys(store.state.base.landlordDetails).length === 0)
    await store.dispatch(baseConstants.withNamespace(baseConstants.action.LOAD_METADATA))
  if (route.params.project && store.state.project.activeProject !== route.params.project) {
    store.dispatch(
      projectConstants.withNamespace(projectConstants.action.CHANGE_ACTIVE_PROJECT),
      route.params.project
    )
  }
}
