import { ProjectState } from './-types'
import constants from './-constants'

const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_EMPTY_FILTERS,
  SET_TOTAL_AVAILABLE_RESULTS,
  SET_CURRENT_SPACE,
  SET_SURFACE_MULTIPLIER,
  SET_SURFACE_NAME,
  SET_SPACE_DATA,
  SET_DEFAULT_SURFACE_FIELDS
} = constants.mutation

export default {
  [SET_LANDLORD_DETAILS](state: ProjectState, data: any) {
    state.projects = data.projects
    state.buildings = data.buildings
  },
  [SET_PROJECT_AVAILABILITY](state: ProjectState, data: any) {
    if (data.buildings) {
      state.project.buildings = data.buildings
    }
    if (data.floors) {
      state.project.floors = data.floors
    }
    if (data.spaces) {
      state.project.spaces = data.spaces
    }
    if (data.amenities) {
      state.project.amenities = data.amenities
    }
    if (data.phases) {
      state.project.phases = data.phases
    }
  },
  [SET_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.isLoading = payload
  },
  [SET_PROJECT_LOADING_STATE](state: ProjectState, payload: boolean) {
    state.project.isLoading = payload
  },
  [SET_ACTIVE_PROJECT](state: ProjectState, payload: string) {
    state.activeProject = payload
  },
  [SET_CURRENT_SPACE](state: ProjectState, payload: string) {
    state.project.currentSpaceId = payload
  },
  [SET_FILTER_STATE](state: ProjectState, payload: any) {
    if (payload.type === 'showSoldApartments' && !!payload.value) {
      state.filters.showOnlySoldApartments = false
    }
    if (payload.type === 'showOnlySoldApartments' && !!payload.value) {
      state.filters.showSoldApartments = false
    }
    state.filters[payload.type] = payload.value
  },
  [SET_EMPTY_FILTERS](state: ProjectState, payload: any) {
    Object.keys(payload).forEach(function (key) {
      state.filters[key] = payload[key]
    })
  },
  [SET_TOTAL_AVAILABLE_RESULTS](state: ProjectState, payload: any) {
    state.availabilityResults.availableSpaces = payload.availableSpaces || 0
  },
  [SET_SURFACE_MULTIPLIER](state: ProjectState, payload: any) {
    state.surfaceMultiplier = payload
  },
  [SET_SURFACE_NAME](state: ProjectState, payload: any) {
    state.surfaceName = payload
  },
  [SET_DEFAULT_SURFACE_FIELDS](state: ProjectState, payload: any) {
    state.surfaceName = 'GLA'
    state.surfaceMultiplier = 1
  },
  [SET_SPACE_DATA](state: ProjectState, payload: any) {
    state.spaceData = payload
  }
}
