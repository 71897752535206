import { TutorialState } from './-types'

export default (): TutorialState => ({
  cookieLoaded: false,
  LEAVE_PAGE: false,
  INFO: false,
  INFO_MOBILE: false,
  CANVAS_INTERACTION: false,
  CANVAS_INTERACTION_MOBILE: false,
  MEZZANINE_GROUND_CHANGER: false,
  MEZZANINE_GROUND_CHANGER_MOBILE: false,
  VIEW_CHANGER_TO_2D_VT: false,
  VIEW_CHANGER_TO_2D_VT_MOBILE: false,
  VIEW_CHANGER_TO_2D_3D: false,
  VIEW_CHANGER_TO_2D_3D_MOBILE: false,
  SPACE_ZOOM_MOBILE: false,
  APARTMENT_INFO: false,
  APARTMENT_INFO_MOBILE: false,
  BUILDING_HIGHLIGHT_EXIT_MOBILE: false,
  LANGUAGE_CHANGER_MOBILE: false,
  AVAILABILITY_FILTERS_MOBILE: false,
  FITOUT_CHANGER: false,
  FITOUT_CHANGER_MOBILE: false
})
