import { ActionContext } from 'vuex'
import constants from './-constants'
import utilityActions from './actions/utility'
import functionalActions from './actions/functional'
import { GuidedTourData, GuidedTourListItem, GuidedTourLocation } from '~/store/guidedTour/-types'
import { WebApi } from '~/helpers/api/WebApi'

const {
  SET_AVAILABLE_TOURS,
  SET_GUIDED_TOUR_IDENTIFIER,
  GET_GUIDED_TOUR_DETAILS,
  SET_CURRENT_LOCATION,
  SET_ON_CLEAR_PRESENTATION,
  SET_ON_SHOW_PRESENTATION
} = constants.action
const {
  MUTATE_AVAILABLE_TOURS,
  MUTATE_GUIDED_TOUR_IDENTIFIER,
  MUTATE_GUIDED_TOUR_DETAILS,
  MUTATE_LOCATIONS,
  MUTATE_CURRENT_LOCATION,
  MUTATE_ON_CLEAR_PRESENTATION,
  MUTATE_ON_SHOW_PRESENTATION
} = constants.mutation

export default {
  ...functionalActions,
  ...utilityActions,
  ...utilityActions,
  [SET_AVAILABLE_TOURS](
    { commit, dispatch }: ActionContext<any, any>,
    payload: GuidedTourListItem[]
  ) {
    if (payload.length === 1) {
      commit(MUTATE_GUIDED_TOUR_IDENTIFIER, payload[0].uuid)
      dispatch(GET_GUIDED_TOUR_DETAILS)
    }
    commit(MUTATE_AVAILABLE_TOURS, payload)
  },
  [SET_GUIDED_TOUR_IDENTIFIER]({ commit }: ActionContext<any, any>, payload: string) {
    commit(MUTATE_GUIDED_TOUR_IDENTIFIER, payload)
  },
  [GET_GUIDED_TOUR_DETAILS]({ commit, rootGetters, state }: ActionContext<any, any>) {
    const webApi = (this as any).$webApi as WebApi
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(webApi.guidedTour.details(state.uuid))
        .then((res: any) => {
          const result = res.data.result as GuidedTourData
          commit(MUTATE_GUIDED_TOUR_DETAILS, result)
          commit(MUTATE_LOCATIONS, result.locations)
          resolve({
            result: res,
            error: null
          })
        })
        .catch((e: any) => {
          commit(MUTATE_GUIDED_TOUR_DETAILS, undefined)
          commit(MUTATE_LOCATIONS, [])
          reject(new Error(e.message))
        })
    })
  },
  [SET_CURRENT_LOCATION]({ commit }: ActionContext<any, any>, payload: string) {
    commit(MUTATE_CURRENT_LOCATION, payload)
  },
  [SET_ON_CLEAR_PRESENTATION]({ commit }: ActionContext<any, any>, payload: () => void) {
    commit(MUTATE_ON_CLEAR_PRESENTATION, payload)
  },
  [SET_ON_SHOW_PRESENTATION](
    { commit }: ActionContext<any, any>,
    payload: (location: GuidedTourLocation) => void
  ) {
    commit(MUTATE_ON_SHOW_PRESENTATION, payload)
  }
}
