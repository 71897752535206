import { ProjectState } from './-types'

export default (): ProjectState => ({
  isLoading: true,
  activeProject: '',
  projects: [],
  buildings: [],
  project: {
    isLoading: true,
    buildings: [],
    floors: [],
    phases: [],
    spaces: [],
    amenities: [],
    currentSpaceId: ''
  },
  filters: {
    building: -1,
    location: 'all',
    floors: [],
    surface: -1,
    // noRooms: {
    //   id: -1,
    //   numberOfRooms: -1,
    //   text: 'All',
    //   spaceType: 'Standard'
    // },
    spaceType: -1,
    orientation: 'all',
    availability: -1,
    priceRange: [],
    priceRangeUniverse: [],
    surfaceRange: [],
    surfaceRangeUniverse: [],
    amenities: [],
    showSoldApartments: false,
    showOnlySoldApartments: false
  },
  availabilityResults: {
    availableSpaces: 0
  },
  surfaceMultiplier: 1,
  surfaceName: 'GLA',
  spaceData: null
})
