import { RequestOfferState } from './-types'
import constants from './-constants'

const { SET_SPACES, CLEAR_STATE, SET_CART_SPACES_STATE, SET_SPACES_BY_BUILDING } =
  constants.mutation

export default {
  [SET_SPACES](state: RequestOfferState, data: any[]) {
    state.spaces = data
  },
  [SET_CART_SPACES_STATE](state: RequestOfferState, payload: boolean) {
    state.cartSpacesIsOpen = payload
  },
  [CLEAR_STATE](state: RequestOfferState) {
    state.spaces = []
  },
  [SET_SPACES_BY_BUILDING](state: RequestOfferState, data: any[]) {
    state.spaces = data
  }
}
