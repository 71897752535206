import { ActionContext } from 'vuex'
import constants from './-constants'

const { CHANGE_MODAL_STATE, CLOSE_MODAL } = constants.action
const { SET_MODAL_STATE } = constants.mutation

export default {
  [CHANGE_MODAL_STATE]({ commit, state }: ActionContext<any, any>, payload: boolean) {
    commit(SET_MODAL_STATE, payload)
  },
  [CLOSE_MODAL]({ commit }: ActionContext<any, any>) {
    commit(SET_MODAL_STATE, {
      showModal: false,
      modalType: ''
    })
  }
}
