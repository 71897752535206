import { BaseState } from './-types'

export default {
  sidebarOpenState(state: BaseState) {
    return state.sidebarOpen
  },
  cdnBase(state: BaseState) {
    return state.meta.generalConfig.cdnBase
  },
  logoImage(state: BaseState) {
    return state.meta.generalConfig.logo
  },
  logoImageWhite(state: BaseState) {
    return state.meta.generalConfig.logoWhite
  }
}
