// Actions
const TRIGGER_PANEL = 'triggerPanel'
const LOAD_FROM_COOKIES = 'loadFromCookies'

// Mutations
const SET_PANEL = 'setPanel'
const SET_STATE = 'setState'

// Namespace
const NAMESPACE = 'tutorial'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    TRIGGER_PANEL,
    LOAD_FROM_COOKIES
  },
  namespaceAction: {
    TRIGGER_PANEL: withNamespace(TRIGGER_PANEL),
    LOAD_FROM_COOKIES: withNamespace(LOAD_FROM_COOKIES)
  },
  mutation: {
    SET_PANEL,
    SET_STATE
  },
  namespace: NAMESPACE,
  withNamespace
}
