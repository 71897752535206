import { AvailabilityState, SurfaceRangeOptions } from './-types'

export default (): AvailabilityState => ({
  isLoadingData: true,
  isLoadingStatisticsData: true,
  includeLeasedSpaces: false,
  project: [],
  building: [],
  floor: [],
  floorMeta: {},
  space: [],
  spaceMeta: {},
  spaceStatisticsData: {
    totalSpaces: 0,
    availableSpaces: 0,
    unavailableSpaces: 0
  },
  filters: {
    clearedFilters: false,
    projectId: undefined,
    phaseId: undefined,
    buildingId: undefined,
    floorId: undefined,
    floorNo: undefined,
    minSurface: 0,
    maxSurface: 0,
    minPrice: 0,
    maxPrice: 0,
    perPage: -1,
    page: 1,
    surface_field: SurfaceRangeOptions.UTIL_SQM,
    space_type_id: undefined,
    orientation: undefined,
    amenities: undefined,
    priceRange: {
      min: 0,
      max: 0
    },
    surfaceRange: {
      [SurfaceRangeOptions.UTIL_SQM]: {
        min: 0,
        max: 0
      },
      [SurfaceRangeOptions.CONSTRUCTED_SQM]: {
        min: 0,
        max: 0
      },
      [SurfaceRangeOptions.GLA]: {
        min: 0,
        max: 0
      },
      [SurfaceRangeOptions.NLA]: {
        min: 0,
        max: 0
      },
      [SurfaceRangeOptions.LFA]: {
        min: 0,
        max: 0
      }
    },
    filters: {
      available: true
    }
  }
})
