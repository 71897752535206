// Actions
const INIT_UNIVERSE_HELPER_METHODS = 'initUniverseHelperMethods'
const INIT_PROJECTS_CARDS_STATE = 'initProjectsCardsState'
const UPDATE_CARD_STATE = 'updateCardState'
const TOGGLE_PROJECT_CARD_STATE = 'toggleProjectCardState'
const TOGGLE_INFO_BOX = 'toggleInfoBox'
const TOGGLE_SHORTLIST_INFO_BOX = 'toggleShortlistInfoBox'
const CHANGE_LOADER_STATE = 'changeLoaderState'
const TOGGLE_APARTMENT_VIEW = 'changeApartmentViewState'

// Mutations
const SET_UNIVERSE_HELPER_METHODS = 'setUniverseHelperMethods'
const SET_PROJECTS_CARDS_STATE = 'setProjectsCardsState'
const SET_CARD_UPDATED_STATE = 'setCardUpdatedState'
const SET_INFO_BOX_STATE = 'setInfoBoxState'
const SET_SHORTLIST_INFO_STATE = 'setShortlistInfoState'
const SET_LOADER_STATE = 'setLoaderState'
const SET_APARTMENT_VIEW_MODE_STATE = 'setApartmentViwModeState'

// Namespace
const NAMESPACE = 'universe'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    INIT_UNIVERSE_HELPER_METHODS,
    INIT_PROJECTS_CARDS_STATE,
    UPDATE_CARD_STATE,
    TOGGLE_PROJECT_CARD_STATE,
    TOGGLE_INFO_BOX,
    CHANGE_LOADER_STATE,
    TOGGLE_SHORTLIST_INFO_BOX,
    TOGGLE_APARTMENT_VIEW
  },
  mutation: {
    SET_UNIVERSE_HELPER_METHODS,
    SET_PROJECTS_CARDS_STATE,
    SET_CARD_UPDATED_STATE,
    SET_INFO_BOX_STATE,
    SET_LOADER_STATE,
    SET_SHORTLIST_INFO_STATE,
    SET_APARTMENT_VIEW_MODE_STATE
  },
  namespace: NAMESPACE,
  withNamespace
}
