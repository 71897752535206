// Actions
const SET_AVAILABLE_TOURS = 'setAvailableTours'
const SET_GUIDED_TOUR_IDENTIFIER = 'setGuidedTourIdentifier'
const GET_GUIDED_TOUR_DETAILS = 'getGuidedTourDetails'
const SET_ON_CLEAR_PRESENTATION = 'setOnClearPresentation'
const SET_ON_SHOW_PRESENTATION = 'setOnShowPresentation'
const SET_CURRENT_LOCATION = 'setCurrentLocation'

// Mutations
const MUTATE_AVAILABLE_TOURS = 'mutateAvailableTours'
const MUTATE_GUIDED_TOUR_IDENTIFIER = 'mutateGuidedTourIdentifier'
const MUTATE_GUIDED_TOUR_DETAILS = 'mutateGuidedTourDetails'
const MUTATE_IS_PLAYING = 'mutateIsPlaying'
const MUTATE_ON_CLEAR_PRESENTATION = 'mutateOnClearPresentation'
const MUTATE_ON_SHOW_PRESENTATION = 'mutateOnShowPresentation'
const MUTATE_SKIP_WALKTHROUGH = 'mutateSkipWalkthrough'
const MUTATE_SKIP_ANIMATION = 'mutateSkipAnimation'
const MUTATE_LOCATIONS = 'mutateLocations'
const MUTATE_CURRENT_LOCATION = 'mutateCurrentLocation'
const MUTATE_AUTO_CONTINUE_DELAY = 'mutateAutoContinueDelay'
const MUTATE_AUTO_CONTINUE_TIMEOUT = 'mutateAutoContinueTimeout'
const MUTATE_TIME_TO_STAY = 'mutateTimeToStay'
const MUTATE_TIME_TO_STAY_TIMEOUT = 'mutateTimeToStayTimeout'
const MUTATE_SHOULD_BYPASS_DELAY = 'mutateShouldBypassDelay'
const MUTATE_GUIDED_TOUR_MODE_ENABLED = 'mutateGuidedTourModeEnabled'

// Namespace
const NAMESPACE = 'guidedTour'

const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    SET_AVAILABLE_TOURS,
    SET_GUIDED_TOUR_IDENTIFIER,
    GET_GUIDED_TOUR_DETAILS,
    SET_ON_CLEAR_PRESENTATION,
    SET_ON_SHOW_PRESENTATION,
    SET_CURRENT_LOCATION
  },
  mutation: {
    MUTATE_AVAILABLE_TOURS,
    MUTATE_GUIDED_TOUR_IDENTIFIER,
    MUTATE_GUIDED_TOUR_DETAILS,
    MUTATE_IS_PLAYING,
    MUTATE_ON_CLEAR_PRESENTATION,
    MUTATE_ON_SHOW_PRESENTATION,
    MUTATE_SKIP_WALKTHROUGH,
    MUTATE_SKIP_ANIMATION,
    MUTATE_LOCATIONS,
    MUTATE_CURRENT_LOCATION,
    MUTATE_AUTO_CONTINUE_DELAY,
    MUTATE_AUTO_CONTINUE_TIMEOUT,
    MUTATE_TIME_TO_STAY,
    MUTATE_TIME_TO_STAY_TIMEOUT,
    MUTATE_SHOULD_BYPASS_DELAY,
    MUTATE_GUIDED_TOUR_MODE_ENABLED
  },
  namespace: NAMESPACE,
  withNamespace
}
