export default ({ store }) => {
  if (process.client) {
    const { googleTagManagerId = 'GTM-XXXXXXX', googleTagManagerEnabled = false } =
      store.state.base.meta.generalConfig
    if (googleTagManagerEnabled) {
      init(window, document, 'script', 'dataLayer', googleTagManagerId)
    }
  }
}

const init = (window, document, script, layer, gtagId) => {
  window[layer] = window[layer] || []
  window[layer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  const f = document.getElementsByTagName(script)[0]
  const j = document.createElement(script)
  const dl = layer !== 'dataLayer' ? '&l=' + layer : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtagId + dl
  f.parentNode.insertBefore(j, f)
}
