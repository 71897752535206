import constants from './-constants'
import { AvailabilityState } from './-types'

export default {
  [constants.getter.GET_PROJECT_BUILDINGS](state: AvailabilityState) {
    if (!state.filters.projectId) {
      return state.project
    }
    return state.project.filter((b) => b.project_id === state.filters.projectId)
  },
  [constants.getter.GET_BUILDING_FLOORS](state: AvailabilityState) {
    if (!state.filters.buildingId) {
      return state.building
    }
    return state.building.filter((f) => f.building_id === state.filters.buildingId)
  },
  [constants.getter.GET_FLOOR_SPACES](state: AvailabilityState) {
    if (!state.filters.floorId) {
      return state.floor
    }
    return state.floor.filter((s) => s.floor_id === state.filters.floorId)
  },
  [constants.getter.GET_BUILDING_SPACES](state: AvailabilityState) {
    if (!state.filters.buildingId) {
      return state.space
    }
    return state.space.filter((s) => s.building_id === state.filters.buildingId)
  },
  [constants.getter.GET_PROJECT_SPACES](state: AvailabilityState) {
    if (!state.filters.projectId) {
      return state.space
    }
    return state.space.filter((s) => s.project_id === state.filters.projectId)
  },
  [constants.getter.GET_FILTERS](state: AvailabilityState) {
    return state.filters
  }
}
