import { UniverseState } from './-types'

export default (): UniverseState => ({
  cards: [],
  universe: {
    methods: {},
    loader: {
      isLoaderActive: true,
      contentData: {
        project: 'Interoverse.ro',
        subtitle: '',
        text: '',
        title: ''
      }
    }
  },
  infoBox: {
    open: false
  },
  shortlistInfo: {
    open: false
  },
  apartmentView: false
})
