import { OfferState } from './-types'
import constants from './-constants'

const { SET_OFFER_DETAILS } = constants.mutation
export default {
  [SET_OFFER_DETAILS](state: OfferState, data: any) {
    state.offer = data
    state.sections = data.sections
  }
}
