import { ActionContext } from 'vuex'
import constants from './-constants'

const {
  INIT_UNIVERSE_HELPER_METHODS,
  INIT_PROJECTS_CARDS_STATE,
  UPDATE_CARD_STATE,
  TOGGLE_PROJECT_CARD_STATE,
  TOGGLE_INFO_BOX,
  CHANGE_LOADER_STATE,
  TOGGLE_SHORTLIST_INFO_BOX,
  TOGGLE_APARTMENT_VIEW
} = constants.action
const {
  SET_UNIVERSE_HELPER_METHODS,
  SET_PROJECTS_CARDS_STATE,
  SET_CARD_UPDATED_STATE,
  SET_INFO_BOX_STATE,
  SET_LOADER_STATE,
  SET_SHORTLIST_INFO_STATE,
  SET_APARTMENT_VIEW_MODE_STATE
} = constants.mutation

export default {
  [INIT_UNIVERSE_HELPER_METHODS]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_UNIVERSE_HELPER_METHODS, {
      methods: payload.methods
    })
  },
  [INIT_PROJECTS_CARDS_STATE]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_PROJECTS_CARDS_STATE, {
      cards: payload.cards
    })
  },
  [UPDATE_CARD_STATE]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_CARD_UPDATED_STATE, {
      mesh_id: payload.mesh_id,
      open: payload.open
    })
  },
  [TOGGLE_PROJECT_CARD_STATE]({ commit, state }: ActionContext<any, any>, payload: any) {
    const foundCardToUpdate = state.universe.cards.find((c: any) => c.slug === payload.projectSlug)
    const otherCards = state.universe.cards.filter((c: any) => c.slug !== payload.projectSlug)
    otherCards.forEach((o: any) => {
      o.open = false // clear other projects cards state from universeStore
    })
    foundCardToUpdate.open = !foundCardToUpdate.open // toggle clicked card open state

    // commit(SET_PROJECT_CARD_STATE, {
    //     cards: payload.cards
    // });
  },
  [TOGGLE_INFO_BOX]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_INFO_BOX_STATE, {
      open: payload.open
    })
  },
  [TOGGLE_SHORTLIST_INFO_BOX]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_SHORTLIST_INFO_STATE, {
      open: payload.open
    })
  },
  [TOGGLE_APARTMENT_VIEW]({ commit }: ActionContext<any, any>, payload: any) {
    commit(SET_APARTMENT_VIEW_MODE_STATE, payload)
  },
  [CHANGE_LOADER_STATE]({ commit }: ActionContext<any, any>, { isLoading }: any) {
    commit(SET_LOADER_STATE, {
      isLoading
    })
  }
}
