import { render, staticRenderFns } from "./underConstruction.vue?vue&type=template&id=2e5aa3f6&scoped=true&"
import script from "./underConstruction.vue?vue&type=script&lang=js&"
export * from "./underConstruction.vue?vue&type=script&lang=js&"
import style0 from "./underConstruction.vue?vue&type=style&index=0&id=2e5aa3f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e5aa3f6",
  null
  
)

/* custom blocks */
import block0 from "@/lang/UnderConstruction.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Flayouts%2FunderConstruction.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports