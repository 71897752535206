class ActiveTransitionHolder {
  private _transition: any

  constructor(initialValue: any) {
    this._transition = initialValue
  }

  // Getter for the value
  get transition(): any {
    return this._transition
  }

  // Setter for the value
  set transition(newValue: any) {
    this._transition = newValue
  }

  // Method to update the value
  updateValue(newValue: any): void {
    this._transition = newValue
  }
}

export const activeTransition = new ActiveTransitionHolder(null)
