import { render, staticRenderFns } from "./embed.vue?vue&type=template&id=24c807c7&"
import script from "./embed.vue?vue&type=script&lang=js&"
export * from "./embed.vue?vue&type=script&lang=js&"
import style0 from "./embed.vue?vue&type=style&index=0&id=24c807c7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/ProjectTemplate.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Flayouts%2Fembed.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomLocaleSwitch: require('/app/components/atoms/common/AtomLocaleSwitch.vue').default,OrganismsOrganismMenuBarEmbedProject: require('/app/components/organisms/OrganismMenuBarEmbedProject.vue').default})
