import { ActionContext } from 'vuex'
import constants, {
  CurrentView,
  FloorViewMode,
  SpaceFloorViewMode,
  ProjectFocusMode
} from './-constants'
import { BuildingState } from '~/store/building/-types'

// const {
//   TOGGLE_SIDEBAR,
//   CHANGE_SIDEBAR_STATE,
//   TOGGLE_FILTER,
//   TOGGLE_INFO,
//   CLOSE_FILTER,
//   CHANGE_FILTER_STATE,
//   LOAD_METADATA,
//   CHANGE_PAGE_LOADING_STATE,
//   TOGGLE_MOBILE_AVAILABILITY_MENU,
//   CHANGE_MOBILE_AVAILABILITY_MENU,
//   CHANGE_DOCUMENT_READY_STATE
// } = constants.action
// const {
//   SET_METADATA,
//   SET_SIDEBAR_STATE,
//   SET_FILTER_STATE,
//   SET_PAGE_LOADING_STATE,
//   SET_MOBILE_AVAILABILITY_MENU,
//   SET_DOCUMENT_READY_STATE
// } = constants.mutation

export default {
  clearSpaceData({ commit }: ActionContext<any, any>) {
    commit('clearSpaceData')
  },
  changeLoadingScreenStatus({ commit }: ActionContext<any, any>, payload: boolean) {
    commit('setLoadingScreenStatus', payload)
  },
  viewSpace({ commit, state }: ActionContext<any, any>, payload: any) {
    const data = {
      space: payload.space,
      floor: payload.floor,
      building: payload.building,
      floorType: payload.floorType,
      spaceData: payload.spaceData
    }
    commit('setSpace', data)
  },

  initBuilding({ commit, state }: ActionContext<any, any>, payload: any) {
    commit('setCurrentView', CurrentView.BUILDING)
    const newState = {
      currentView: CurrentView.BUILDING,
      canvasElement: payload.canvasReference,
      cdnBase: payload.cdnBase,
      project: payload.project,
      activeProject: payload.activeProject,
      client: payload.client,
      space: state.space,
      isInitialized: true,
      settings: payload.settings
    } as BuildingState
    commit('setInitialState', newState)
  },

  initFloor({ commit, state }: ActionContext<any, any>, payload: any) {
    commit('setCurrentView', CurrentView.FLOOR)
    commit('setInitialState', {
      currentView: CurrentView.FLOOR,
      canvasElement: payload.canvasReference,
      cdnBase: payload.cdnBase,
      project: payload.project,
      activeProject: payload.activeProject,
      client: payload.client,
      space: state.space,
      isInitialized: true,
      settings: payload.settings,
      fitoutData: {}
    } as BuildingState)
  },
  destroy({ state, commit }: ActionContext<any, any>) {
    commit('clearState')
  },
  changeViewMode({ state, commit }: ActionContext<any, any>, payload: FloorViewMode) {
    commit('setFloorViewMode', payload)
  },
  setDefaultFilterPinsMode({ state, commit }: ActionContext<any, any>, payload: string) {
    commit('mutateDefaultFilterPinsMode', payload)
  },
  changeProjectFocusMode({ state, commit }: ActionContext<any, any>, payload: ProjectFocusMode) {
    commit('setProjectFocusMode', payload)
  },
  changeSpaceViewMode({ state, commit }: ActionContext<any, any>, payload: SpaceFloorViewMode) {
    commit('setSpaceFloorViewMode', payload)
  }
}
