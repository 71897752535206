import { TutorialState } from './-types'
import constants from './-constants'

const { SET_PANEL, SET_STATE } = constants.mutation

export default {
  [SET_PANEL](state: TutorialState, panel: string) {
    state[panel as keyof TutorialState] = true
    // @ts-ignore / TODO - rethink the eslint rule
    this.$cookiz.set('tutorial', state)
  },
  [SET_STATE](state: TutorialState, newState: TutorialState) {
    state.cookieLoaded = true
    Object.keys(newState).forEach(function (key) {
      state[key as keyof TutorialState] = newState[key as keyof TutorialState]
    })
  }
}
