// Actions
const LOAD_SPACE_DETAILS = 'loadSpaceDetails'

// mutations
const SET_SPACE_DETAILS = 'setSpaceDetails'

// Getters
const GET_SPACE = 'getSpace'

// Namespace
const NAMESPACE = 'space'

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`
}

export default {
  action: {
    LOAD_SPACE_DETAILS
  },
  mutation: {
    SET_SPACE_DETAILS
  },
  getter: {
    GET_SPACE
  },
  namespace: NAMESPACE,
  withNamespace
}
