export default ({ store }) => {
  if (process.client) {
    const { cookiebotId = '', cookiebotEnabled = false } = store.state.base.meta.generalConfig
    if (cookiebotEnabled) {
      init(cookiebotId)
    }
  }
}
const init = (cookiebotId) => {
  if (!cookiebotId) return
  const script = document.createElement('script')
  script.id = 'Cookiebot'
  script.type = 'text/javascript'
  script.src = 'https://consent.cookiebot.com/uc.js'
  script.setAttribute('data-cbid', cookiebotId)
  script.setAttribute('async', 'true')
  document.head.appendChild(script)
}
