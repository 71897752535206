import { GuidedTourLocation, GuidedTourState, LocationType } from '~/store/guidedTour/-types'

export default {
  getCurrentLocationData(state: GuidedTourState): GuidedTourLocation | undefined {
    return state.locations.find((l) => l.uuid === state.currentLocation)
  },
  presentationLocations(state: GuidedTourState): GuidedTourLocation[] {
    return state.locations.filter((l) => l.type === LocationType.PRESENTATION)
  },
  gatewayLocations(state: GuidedTourState): GuidedTourLocation[] {
    return state.locations.filter((l) => l.type === LocationType.GATEWAY)
  },
  timelineLocations(state: GuidedTourState): GuidedTourLocation[] {
    return state.locations.filter((l) => l.show_in_timeline)
  },
  hasCurrentLocation(state: GuidedTourState): boolean {
    return !!state.locations.find((l) => l.uuid === state.currentLocation)
  },
  currentSequence(state: GuidedTourState): number | undefined {
    return state.locations.find((l) => l.uuid === state.currentLocation)?.sequence_order
  },
  locationCount(state: GuidedTourState): number | undefined {
    return state.locations.length
  },
  engine3d(state: GuidedTourState) {
    return (window as any).engine3d?.default()
  }
}
