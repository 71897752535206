import { ActionContext } from 'vuex'
import constants from '../-constants'
import AnalyticsLayer from '~/helpers/analytics'
import { AnimationType, GuidedTourLocation, LocationType } from '~/store/guidedTour/-types'
import { activeTransition } from '~/store/guidedTour/actions/common'

const {
  MUTATE_GUIDED_TOUR_MODE_ENABLED,
  MUTATE_IS_PLAYING,
  MUTATE_SHOULD_BYPASS_DELAY,
  MUTATE_CURRENT_LOCATION,
  MUTATE_TIME_TO_STAY_TIMEOUT,
  MUTATE_TIME_TO_STAY,
  MUTATE_AUTO_CONTINUE_TIMEOUT,
  MUTATE_AUTO_CONTINUE_DELAY,
  MUTATE_ON_SHOW_PRESENTATION,
  MUTATE_ON_CLEAR_PRESENTATION
} = constants.mutation

export default {
  async nextLocation({ commit, dispatch, state, getters }: ActionContext<any, any>) {
    AnalyticsLayer.recordEvent('next_location_trigger_guided_tour', {})
    const locations = state.skipWalkthrough ? getters.presentationLocations : state.locations
    if (!getters.hasCurrentLocation) {
      const startLocation = locations?.[0]
      if (startLocation) {
        await dispatch('checkIfLocationIsPresentation', startLocation)
        commit(MUTATE_CURRENT_LOCATION, startLocation.uuid)
        await dispatch('handleFadeTo', startLocation)
      }
      return
    }
    const currentLocationData = getters.getCurrentLocationData
    const currentIndex = locations.findIndex(
      (l: GuidedTourLocation) => l.uuid === currentLocationData?.uuid
    )
    if (currentIndex + 1 >= locations.length) {
      return
    }
    const nextLocation = locations?.[currentIndex + 1]
    await dispatch('checkIfLocationIsPresentation', nextLocation)
    commit(MUTATE_CURRENT_LOCATION, nextLocation.uuid)
    nextLocation?.animation_type === AnimationType.FADE
      ? await dispatch('handleFadeTo', nextLocation)
      : await dispatch('handleFastTravel', nextLocation)
  },
  async prevLocation({ commit, dispatch, state, getters }: ActionContext<any, any>) {
    AnalyticsLayer.recordEvent('prev_location_trigger_guided_tour', {})

    const locations = state.skipWalkthrough ? getters.presentationLocations : state.locations
    if (!getters.hasCurrentLocation) {
      return
    }

    const currentLocationData = getters.getCurrentLocationData
    const currentIndex = locations.findIndex(
      (l: GuidedTourLocation) => l.uuid === currentLocationData?.uuid
    )
    if (currentIndex - 1 < 0) {
      return
    }
    const prevLocation = locations?.[currentIndex - 1]
    await dispatch('checkIfLocationIsPresentation', prevLocation)
    commit(MUTATE_CURRENT_LOCATION, prevLocation.uuid)
    dispatch('handleFadeTo', prevLocation)
  },
  handleAutoContinue(
    { commit, dispatch, state }: ActionContext<any, any>,
    nextLocation: GuidedTourLocation
  ) {
    if (
      nextLocation.type === LocationType.PRESENTATION &&
      nextLocation?.autocontinue_enabled &&
      nextLocation.autocontinue_delay_ms > 0
    ) {
      commit(MUTATE_AUTO_CONTINUE_DELAY, nextLocation.autocontinue_delay_ms)
      commit(
        MUTATE_AUTO_CONTINUE_TIMEOUT,
        setTimeout(() => {
          dispatch('autoContinueTour')
        }, nextLocation.autocontinue_delay_ms)
      )
    }
  },
  async processNextLocation({ commit, dispatch, getters, state }: ActionContext<any, any>) {
    const locations = state.skipWalkthrough ? state.presentationLocations : state.locations

    const currentLocationData = getters.getCurrentLocationData

    const currentIndex = locations.findIndex(
      (l: GuidedTourLocation) => l.uuid === currentLocationData?.uuid
    )

    if (currentIndex >= locations.length - 1 || !state.isPlaying) return // Base case: stop if we're done or if the tour isn't playing

    const currentLocation = locations[currentIndex] ? locations[currentIndex] : locations[0]
    const nextLocation = locations[currentIndex + 1]
    const sameCameraPosition = await dispatch('checkCameraTarget', currentLocation)

    if (
      currentLocation.type === LocationType.GATEWAY &&
      currentLocation.time_to_stay_ms > 0 &&
      !state.shouldBypassDelay
    ) {
      commit(MUTATE_TIME_TO_STAY, currentLocation.time_to_stay_ms)
      commit(
        MUTATE_TIME_TO_STAY_TIMEOUT,
        setTimeout(async () => {
          !sameCameraPosition
            ? await dispatch('handleLocationFade', nextLocation)
            : nextLocation.animation_type === AnimationType.FADE
            ? await dispatch('handleLocationFade', nextLocation)
            : await dispatch('handleFastTravel', nextLocation)
          await dispatch('checkIfLocationIsPresentation', nextLocation)
          commit(MUTATE_CURRENT_LOCATION, nextLocation.uuid)
          // Proceed to next location
          await dispatch('handleAutoContinue', nextLocation)

          dispatch('processNextLocation')
        }, currentLocation.time_to_stay_ms)
      )
      return
    }

    !sameCameraPosition
      ? await dispatch('handleLocationFade', nextLocation)
      : nextLocation.animation_type === AnimationType.FADE
      ? await dispatch('handleLocationFade', nextLocation)
      : await dispatch('handleFastTravel', nextLocation)
    await dispatch('checkIfLocationIsPresentation', nextLocation)
    commit(MUTATE_CURRENT_LOCATION, nextLocation.uuid)

    await dispatch('handleAutoContinue', nextLocation)

    // Proceed to next location
    dispatch('processNextLocation')
  },
  autoContinueTour({ commit, dispatch, state }: ActionContext<any, any>) {
    dispatch('clearAutoContinueTimeout')
    commit(MUTATE_ON_CLEAR_PRESENTATION)
    commit(MUTATE_IS_PLAYING, true)
    dispatch('processNextLocation')
  },
  async startTour({ commit, dispatch, state }: ActionContext<any, any>) {
    const isAtLastLocation = await dispatch('checkIfAtLastLocation')
    if (isAtLastLocation) {
      await dispatch('resetTour')
      setTimeout(() => {
        dispatch('startTour')
      }, 100)
      return
    }
    AnalyticsLayer.recordEvent('play_guided_tour', {})
    dispatch('clearAutoContinueTimeout')
    commit(MUTATE_ON_CLEAR_PRESENTATION)
    commit(MUTATE_IS_PLAYING, true)
    dispatch('processNextLocation')
    setTimeout(() => {
      state.shouldBypassDelay && commit(MUTATE_SHOULD_BYPASS_DELAY, false)
    }, 10)
  },
  pauseTour({ commit, dispatch, state }: ActionContext<any, any>) {
    AnalyticsLayer.recordEvent('pause_guided_tour', {})
    dispatch('clearTimeToStayTimeout')
    commit(MUTATE_IS_PLAYING, false)
    if (activeTransition.transition) {
      activeTransition.transition.pause()
    }
  },
  resetTourForCustomNavigation({ commit, dispatch, state }: ActionContext<any, any>) {
    commit(MUTATE_IS_PLAYING, false)
    dispatch('clearTimeToStayTimeout')
    dispatch('clearAutoContinueTimeout')
    commit(MUTATE_SHOULD_BYPASS_DELAY, false)
    commit(MUTATE_CURRENT_LOCATION, '')
    commit(MUTATE_ON_CLEAR_PRESENTATION)
    if (activeTransition.transition) {
      activeTransition.transition?.kill()
      activeTransition.transition = null
    }
  },
  async resetTour({ commit, dispatch, state }: ActionContext<any, any>) {
    AnalyticsLayer.recordEvent('reset_guided_tour', {})
    commit(MUTATE_IS_PLAYING, false)
    dispatch('clearTimeToStayTimeout')
    dispatch('clearAutoContinueTimeout')
    commit(MUTATE_SHOULD_BYPASS_DELAY, false)
    commit(MUTATE_CURRENT_LOCATION, '')
    await dispatch('nextLocation')
    commit(MUTATE_CURRENT_LOCATION, '')
    commit(MUTATE_ON_CLEAR_PRESENTATION)
    if (activeTransition.transition) {
      activeTransition.transition?.kill()
      activeTransition.transition = null
    }
  },
  enableGuidedTourMode({ commit, dispatch }: ActionContext<any, any>) {
    commit(MUTATE_GUIDED_TOUR_MODE_ENABLED, true)
    dispatch('startTour')
  },
  disableGuidedTourMode({ commit, dispatch }: ActionContext<any, any>) {
    commit(MUTATE_GUIDED_TOUR_MODE_ENABLED, false)
    dispatch('resetTour')
  },
  clearTimeToStayTimeout({ commit, dispatch, state }: ActionContext<any, any>) {
    clearTimeout(state.timeToStayTimeout)
    commit(MUTATE_TIME_TO_STAY_TIMEOUT, null)
    commit(MUTATE_TIME_TO_STAY, 0)
  },
  clearAutoContinueTimeout({ commit, dispatch, state }: ActionContext<any, any>) {
    clearTimeout(state.autoContinueTimeout)
    commit(MUTATE_AUTO_CONTINUE_TIMEOUT, null)
    commit(MUTATE_AUTO_CONTINUE_DELAY, 0)
  }
}
