import {
  AvailabilityFilters,
  AvailabilityState,
  PriceRangeType,
  SurfaceRangeOptions,
  SurfaceRangeType
} from './-types'
import constants from './-constants'

const {
  SET_PROJECT_AVAILABILITY_DATA,
  SET_BUILDING_AVAILABILITY_DATA,
  SET_FLOOR_AVAILABILITY_DATA,
  SET_SPACE_AVAILABILITY_DATA,
  SET_FILTERS,
  RESET_AVAILABILITY_DATA,
  RESET_PROJECT_AVAILABILITY_DATA,
  RESET_BUILDING_AVAILABILITY_DATA,
  RESET_FLOOR_AVAILABILITY_DATA,
  RESET_SPACE_AVAILABILITY_DATA,
  RESET_FILTERS,
  SET_IS_LOADING_DATA,
  SET_LEASED_SPACES_VISIBILITY,
  SET_SPACE_STATISTICS_DATA,
  SET_IS_LOADING_STATISTICS_DATA
} = constants.mutation
export default {
  [SET_PROJECT_AVAILABILITY_DATA](state: AvailabilityState, data: any[]) {
    state.project = data
  },
  [SET_BUILDING_AVAILABILITY_DATA](state: AvailabilityState, data: any[]) {
    state.building = data
  },
  [SET_FLOOR_AVAILABILITY_DATA](state: AvailabilityState, data: any[]) {
    state.floor = data
  },
  [SET_SPACE_AVAILABILITY_DATA](state: AvailabilityState, data: any[]) {
    state.space = data
  },
  [RESET_PROJECT_AVAILABILITY_DATA](state: AvailabilityState) {
    state.project = []
  },
  [RESET_BUILDING_AVAILABILITY_DATA](state: AvailabilityState) {
    state.building = []
  },
  [RESET_FLOOR_AVAILABILITY_DATA](state: AvailabilityState) {
    state.floor = []
  },
  [RESET_SPACE_AVAILABILITY_DATA](state: AvailabilityState) {
    state.space = []
  },
  [RESET_AVAILABILITY_DATA](state: AvailabilityState) {
    state.project = []
    state.building = []
    state.floor = []
    state.space = []
  },
  [SET_FILTERS](state: AvailabilityState, payload: AvailabilityFilters) {
    if (typeof payload.floorNo !== 'undefined' && typeof payload.floorNo === 'string') {
      payload.floorNo = undefined
    }
    if (state.filters.buildingId && !payload.buildingId && typeof payload.floorNo !== 'undefined') {
      const specificBuildingFloor = state.building.find(
        (f: any) => f.no === payload.floorNo && f.building_id === state.filters.buildingId
      )
      payload.floorId = specificBuildingFloor?.id
    }
    state.filters = { ...state.filters, ...payload }
    if (typeof payload.surfaceRange !== 'undefined') {
      state.filters.surfaceRange[state.filters.surface_field] =
        payload.surfaceRange[state.filters.surface_field]
    }
    if (typeof payload.priceRange !== 'undefined') {
      state.filters.priceRange = payload.priceRange
    }
  },
  [RESET_FILTERS](
    state: AvailabilityState,
    defaultRange: SurfaceRangeType,
    defaultPriceRange: PriceRangeType
  ) {
    state.filters = {
      ...state.filters,
      clearedFilters: true,
      buildingId: undefined,
      phaseId: undefined,
      floorId: undefined,
      floorNo: undefined,
      perPage: -1,
      page: 1,
      space_type_id: undefined,
      orientation: undefined,
      amenities: undefined,
      surface_field: SurfaceRangeOptions.UTIL_SQM,
      surfaceRange: { ...state.filters.surfaceRange, ...defaultRange },
      priceRange: { ...state.filters.priceRange, ...defaultPriceRange },
      filters: {
        available: true
      }
    }
    state.building = []
    state.floor = []
    state.space = []
  },
  [SET_IS_LOADING_DATA](state: AvailabilityState, payload: boolean) {
    state.isLoadingData = payload
  },
  [SET_LEASED_SPACES_VISIBILITY](state: AvailabilityState, payload: boolean) {
    state.includeLeasedSpaces = payload
  },
  [SET_SPACE_STATISTICS_DATA](
    state: AvailabilityState,
    payload: {
      totalSpaces: number
      availableSpaces: number
      unavailableSpaces: number
    }
  ) {
    state.spaceStatisticsData = payload
  },
  [SET_IS_LOADING_STATISTICS_DATA](state: AvailabilityState, payload: boolean) {
    state.isLoadingStatisticsData = payload
  }
}
