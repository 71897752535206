import { GuidedTourLocation, GuidedTourState } from './-types'

export default (): GuidedTourState => ({
  availableTours: [],
  uuid: '',
  data: undefined,
  locations: [],
  autoContinueTimeout: null,
  autoContinueDelay: 0,
  timeToStayTimeout: null,
  timeToStay: 0,
  currentLocation: '',
  shouldBypassDelay: false,
  guidedTourModeEnabled: false,
  skipWalkthrough: false,
  skipAnimation: false,
  isPlaying: false
})
